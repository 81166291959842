import { isMobile } from '../utils/index'
export default {
  address: '新北市新店區華城路56號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28938.712630918664!2d121.50706240639082!3d24.954575662257742!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3468023299953a1d%3A0x35f0212049e43b2f!2zMjMx5paw5YyX5biC5paw5bqX5Y2A6I-v5Z-O6LevNTbomZ8!5e0!3m2!1szh-TW!2stw!4v1649833176835!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/T59YxkQAce7i3kam7',
  phone: '02-2211-1200',
  fbLink: 'https://www.facebook.com/gramercytw/',
  fbMessage: 'https://m.me/gramercytw',
  caseName: '碧澄2',
  indigatorLength: 10,

  houseInfos: [
    ['投資興建', '襄澐建設有限公司',],
    ['建築設計', '立志建築師事務所',],
    ['碧澄二期案址', '新北市新店區華城路58號',],
    ['坪數規劃', '51坪',],
  ],

  gtmCode: ['W72HX9F','TPG82D3'], // 可放置多個
  recaptcha_site_key_v2: '6LfGUjEaAAAAANYvhZQx5imGm23pRt0V-rOvkpNC',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '預約賞屋',
    subTitle: ''
  }
}
